<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">게시글 등록</div>
            <div v-else class="headline text-start mt-8">게시글 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row v-if="$route.params._board">
                        <v-col cols="6" class="py-0">
                            <v-text-field :value="(board.writer || {}).name" label="작성자" persistent-placeholder hide-details readonly disabled></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-datetime-field :value="board.createdAt ? board.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder hide-details @input="(value) => (board.createdAt = value)"></v-datetime-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-select v-model="board.category" :items="categories" item-text="name" item-value="name" label="카테고리" persistent-placeholder hide-details></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col cols="12" class="py-0" v-if="['about'].includes(board.code)">
                            <v-text-field v-model="board.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-3" v-if="['about'].includes(board.code)">
                            <v-textarea v-model="board.about.content" label="기사" persistent-placeholder hide-details auto-grow outlined rows="1" row-height="15"></v-textarea>
                        </v-col>
                        <v-col cols="6" class="py-0" v-if="!['partners','about'].includes(board.code)">
                            <v-text-field v-model="board.subject" label="제목" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-3" v-if="['partners'].includes(board.code)">
                        <v-col cols="12" class="py-0" >
                            <v-text-field v-model="board.company.about" label="회사소개" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3" v-if="['partners'].includes(board.code)">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="board.company.URL" label="회사 URL" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="board.summary" label="한줄설명" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row> -->
                </v-card-text>
            </v-card>

            <v-card v-if="!['text'].includes($route.query.code)" class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="board.thumb" max-width="96" max-height="96" :src="createObjectURL(board.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="board.thumb" label="썸네일 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="['text', 'thumbnail'].includes(board.code)" class="mt-4">
                <v-card-title class="subtitle-1">첨부파일</v-card-title>
                <v-card-text>
                        <v-col class="py-0">
                            <v-file-input v-model="board.files[0]" label="파일 선택" persistent-placeholder class="mt-2 mx-3"></v-file-input>
                            <v-file-input v-model="board.files[1]" label="파일 선택" persistent-placeholder class="mt-2 mx-3"></v-file-input>
                        </v-col>
                </v-card-text>
            </v-card>

            <v-card v-if="['forms'].includes(board.code)" class="mt-4">
                <v-card-title class="subtitle-1">파일</v-card-title>
                <v-card-text>
                    <v-file-input v-model="board.files[0]" show-size />
                </v-card-text>
            </v-card>

            <v-card v-if="['text', 'thumbnail'].includes($route.query.code)" class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import { mdiTagPlus } from "@mdi/js";
import urlParser from "js-video-url-parser";
import YoutubeCategoriesView from "@/components/console/boards/youtube-categories-view.vue";
import ResourceCategoriesView from "@/components/console/boards/resource-categories-view.vue";
import PartnersCategoriesView from "@/components/console/boards/partners-categories-view.vue";
import ReportViewCategories from '@/components/console/boards/report-view-categories.vue';
import FormsViewCategories from '@/components/console/boards/forms-view-categories.vue';

export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
        YoutubeCategoriesView,
        ReportViewCategories,
        FormsViewCategories,
        ResourceCategoriesView,
        PartnersCategoriesView
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            categories: ["행사 사진", "진로 체험", "대회 훈련"],

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                sido: undefined,
                gugun: undefined,
                area: undefined,
                postcode: undefined,
                address1: undefined,
                address2: undefined,
                lat: undefined,
                lng: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                meta: {
                    buyEnabled: false,
                    buyURL: null,
                    youtube: null,
                },

                company: {
                    name: undefined,
                    about: undefined,
                    URL: undefined
                },

                about: {
                    content: undefined,
                    URL: undefined
                },
                
                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
                files: [],

                language: "ko",
            },

            videoId: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                // if(this.$route.query._clone){
                //     this.board._id = undefined;

                //     if(this.board.thumb){
                //         this.thumb = await this.getResource(this.board.thumb);
                //         this.board.thumb = undefined;
                //     }
                //     for(var i in this.board.images){
                //         if(this.board.images[i]){
                //             this.images[i] = await this.getResource(this.board.images[i]);
                //             this.board.images[i] = undefined;
                //         }
                //     }
                // }

                if (this.$route.params._board) {
                    var { board } = await api.console.boards.get({ _id: this.$route.params._board });
                    board.files = await Promise.all((board.files || []).map(async ({ url }) => await api.getResource(url, true)));
                    if (board.thumb) board.thumb = await api.getResource(board.thumb, true);
                    this.board = board;
                    if (this.board.code == "youtube") this.setVideoId();
                }

                if (!this.$route.params._board === undefined) {
                    var { summary } = await api.console.boards.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code,
                        },
                    });
                    this.board.sequence = summary.totalCount + 1;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _files = [], files = [], thumb, ...board } = this.board;
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 삭제
                ////////////////////////////////////////////////////////////////
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.console.boards.files.delete({ _board: board?._id, _id })));

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ board }] = [board._id ? await api.console.boards.put(board) : await api.console.boards.post(board)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.boards.postThumb(board, thumb);
                if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.console.boards.files.post({ _board: board?._id, index }, file)));

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        setVideoId() {
            this.videoId = urlParser.parse(this.board?.meta?.youtube)?.id || "";
        },
    },
};
</script>
