var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline text-start mt-8",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 게시판 타이틀 "), _vm.filter.code == 'ko' ? _c('span', [_vm._v("(한글)")]) : _vm._e(), _vm.filter.code == 'en' ? _c('span', [_vm._v("(영문)")]) : _vm._e()]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v("product/usww")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "1"
    },
    model: {
      value: _vm.settings.product.uwss.first,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.uwss, "first", $$v);
      },
      expression: "settings.product.uwss.first"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "2"
    },
    model: {
      value: _vm.settings.product.uwss.second,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.uwss, "second", $$v);
      },
      expression: "settings.product.uwss.second"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "3"
    },
    model: {
      value: _vm.settings.product.uwss.third,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.uwss, "third", $$v);
      },
      expression: "settings.product.uwss.third"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v("product/webcastle")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "1"
    },
    model: {
      value: _vm.settings.product.webcastle.first,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.webcastle, "first", $$v);
      },
      expression: "settings.product.webcastle.first"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "2"
    },
    model: {
      value: _vm.settings.product.webcastle.second,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.webcastle, "second", $$v);
      },
      expression: "settings.product.webcastle.second"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "3"
    },
    model: {
      value: _vm.settings.product.webcastle.third,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.webcastle, "third", $$v);
      },
      expression: "settings.product.webcastle.third"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v("product/wsfinder")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "1"
    },
    model: {
      value: _vm.settings.product.wsfinder.first,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.wsfinder, "first", $$v);
      },
      expression: "settings.product.wsfinder.first"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "2"
    },
    model: {
      value: _vm.settings.product.wsfinder.second,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.wsfinder, "second", $$v);
      },
      expression: "settings.product.wsfinder.second"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v("product/wmds")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "1"
    },
    model: {
      value: _vm.settings.product.wmds.first,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.wmds, "first", $$v);
      },
      expression: "settings.product.wmds.first"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "2"
    },
    model: {
      value: _vm.settings.product.wmds.second,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.wmds, "second", $$v);
      },
      expression: "settings.product.wmds.second"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "3"
    },
    model: {
      value: _vm.settings.product.wmds.third,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.wmds, "third", $$v);
      },
      expression: "settings.product.wmds.third"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "4"
    },
    model: {
      value: _vm.settings.product.wmds.fourth,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.wmds, "fourth", $$v);
      },
      expression: "settings.product.wmds.fourth"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "5"
    },
    model: {
      value: _vm.settings.product.wmds.fifth,
      callback: function ($$v) {
        _vm.$set(_vm.settings.product.wmds, "fifth", $$v);
      },
      expression: "settings.product.wmds.fifth"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v("about/demo")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "about"
    },
    model: {
      value: _vm.settings.about,
      callback: function ($$v) {
        _vm.$set(_vm.settings, "about", $$v);
      },
      expression: "settings.about"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "demo"
    },
    model: {
      value: _vm.settings.demo,
      callback: function ($$v) {
        _vm.$set(_vm.settings, "demo", $$v);
      },
      expression: "settings.demo"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }