<template>
    <v-select v-model="category" v-bind="{ ...$attrs, items }" />
</template>

<script>
import { BOARD_PARTNERS_CATEGORIES } from "@/assets/variables";
const categories = Object.values(BOARD_PARTNERS_CATEGORIES);

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        category: null,
    }),
    computed: {
        items() {
            return [{ text: "없음", value: null }, ...categories];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.value !== this.category) this.sync();
        },
        category() {
            if (this.value !== this.category) this.emit();
        },
    },
    methods: {
        sync() {
            this.category = this.value;
        },
        emit() {
            this.$emit("input", this.category);
        },
    },
};
</script>

<style></style>
