<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <v-row justify="center" align="center">
                <v-col cols="auto" class="headline text-start mt-8">
                    게시판 타이틀
                    <span v-if="filter.code == 'ko'">(한글)</span>
                    <span v-if="filter.code == 'en'">(영문)</span>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>

            <v-card class="mt-4">
                <v-card-title>product/usww</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="1" v-model="settings.product.uwss.first"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="2" v-model="settings.product.uwss.second"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="3" v-model="settings.product.uwss.third"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>product/webcastle</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="1" v-model="settings.product.webcastle.first"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="2" v-model="settings.product.webcastle.second"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="3" v-model="settings.product.webcastle.third"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>product/wsfinder</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="1" v-model="settings.product.wsfinder.first"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="2" v-model="settings.product.wsfinder.second"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>product/wmds</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="1" v-model="settings.product.wmds.first"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="2" v-model="settings.product.wmds.second"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="3" v-model="settings.product.wmds.third"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="4" v-model="settings.product.wmds.fourth"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="5" v-model="settings.product.wmds.fifth"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>about/demo</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="about" v-model="settings.about"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field label="demo" v-model="settings.demo"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            filter: {
                code: this.$route.query.code
            },
            settings: {
                _id: null,
                product: {
                    uwss: {
                        first: null,
                        second: null,
                        third: null
                    },
                    webcastle: {
                        first: null,
                        second: null,
                        third: null
                    },
                    wsfinder: {
                        first: null,
                        second: null
                    },
                    wmds: {
                        first: null,
                        second: null,
                        third: null,
                        fourth: null,
                        fifth: null
                    }
                },
                about: null,
                demo: null,
                code: this.$route.query.code
            }
        }
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init() {
            const { settings } = await api.console.boards.settings.get({
                headers: this.filter
            })
            this.settings = settings[0]
        },
        async save() {
            if(this.validate()) {
                const  { settings } = this.settings._id ? await api.console.boards.settings.put(this.settings) : await api.console.boards.settings.post(this.settings)
                this.settings = settings
                alert("저장되었습니다")
            }
        },
        validate() {
            try {
                if(!this.settings.product.uwss.first) throw new Error("uwss 첫 번째 값을 입력해주세요")
                if(!this.settings.product.uwss.second) throw new Error("uwss 두 번째 값을 입력해주세요")
                if(!this.settings.product.uwss.third) throw new Error("uwss 세 번째 값을 입력해주세요")
                if(!this.settings.product.webcastle.first) throw new Error("webcastle 첫 번째 값을 입력해주세요")
                if(!this.settings.product.webcastle.second) throw new Error("webcastle 두 번째 값을 입력해주세요")
                if(!this.settings.product.webcastle.third) throw new Error("webcastle 세 번째 값을 입력해주세요")
                if(!this.settings.product.wsfinder.first) throw new Error("wsfinder 첫 번째 값을 입력해주세요")
                if(!this.settings.product.wsfinder.second) throw new Error("wsfinder 두 번째 값을 입력해주세요")
                if(!this.settings.product.wmds.first) throw new Error("wmds 첫 번째 값을 입력해주세요")
                if(!this.settings.product.wmds.second) throw new Error("wmds 두 번째 값을 입력해주세요")
                if(!this.settings.product.wmds.third) throw new Error("wmds 세 번째 값을 입력해주세요")
                if(!this.settings.product.wmds.fourth) throw new Error("wmds 네 번째 값을 입력해주세요")
                if(!this.settings.product.wmds.fifth) throw new Error("wmds 다섯 번째 값을 입력해주세요")
                if(!this.settings.about) throw new Error("about 값을 입력해주세요")
                if(!this.settings.demo) throw new Error("demo 값을 입력해주세요")
                return true
            } catch(error) {
                alert(error.message)
                return false
            }
        }
    },
    watch: {
        async "$route.query.code"() {
            this.filter.code = this.$route.query.code
            await this.init()
        },
    }
}
</script>
