var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "max-width": "1440",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("연어다음 상품설정")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-date-field', {
    attrs: {
      "label": "시세 적용 날짜",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.campaign.product.saleDate,
      callback: function ($$v) {
        _vm.$set(_vm.campaign.product, "saleDate", $$v);
      },
      expression: "campaign.product.saleDate"
    }
  })], 1)], 1), _c('v-spacer')], 1), _c('v-layout', {
    staticClass: "mt-4 align-end"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("연어다음 캠페인 설정")])]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.pushCampaginItem();
      }
    }
  }, [_vm._v("항목추가")])], 1), _vm._l(_vm.campaigns, function (campaign) {
    return _c('v-row', {
      key: campaign._id
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-card', [_c('v-card-title', [_c('span', {
      staticClass: "caption"
    }, [_c('b', [_vm._v("분류코드")])]), _c('v-text-field', {
      staticClass: "ml-4",
      attrs: {
        "readonly": "",
        "disabled": "",
        "outlined": "",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: campaign.kind,
        callback: function ($$v) {
          _vm.$set(campaign, "kind", $$v);
        },
        expression: "campaign.kind"
      }
    })], 1)], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-card', [_c('v-card-title', [_c('span', {
      staticClass: "caption"
    }, [_c('b', [_vm._v("품목")])]), _c('v-text-field', {
      staticClass: "ml-4",
      attrs: {
        "outlined": "",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: campaign.product.name,
        callback: function ($$v) {
          _vm.$set(campaign.product, "name", $$v);
        },
        expression: "campaign.product.name"
      }
    })], 1)], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('v-card', [_c('v-card-title', [_c('span', {
      staticClass: "caption"
    }, [_c('b', [_vm._v("가격")])]), _c('v-text-field', {
      staticClass: "ml-4",
      attrs: {
        "outlined": "",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: campaign.product.salePrice,
        callback: function ($$v) {
          _vm.$set(campaign.product, "salePrice", $$v);
        },
        expression: "campaign.product.salePrice"
      }
    })], 1)], 1)], 1), _c('v-col', [_c('v-btn', {
      on: {
        "click": function ($event) {
          return _vm.remove(campaign);
        }
      }
    }, [_vm._v("삭제")])], 1)], 1);
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.update(_vm.campaigns);
      }
    }
  }, [_vm._v("저장")]), _c('v-divider', {
    staticClass: "my-4"
  }), _c('div', [_c('div', {
    staticClass: "caption primary--text"
  }, [_c('b', [_vm._v("※ 주의사항")])]), _c('div', {
    staticClass: "caption"
  }, [_c('b', [_vm._v("* 캠페인 설정값은 예전에 신청한 내역에는 영향을 주지 않습니다.")])]), _c('div', {
    staticClass: "caption"
  }, [_c('b', [_vm._v("* 캠페인 설정값을 변경 후에는 저장버튼을 누르셔야 시스템에 반영이 됩니다.")])])])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }