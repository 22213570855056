var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_vm.$route.params._board ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.board.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-datetime-field', {
    attrs: {
      "value": _vm.board.createdAt ? _vm.board.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "input": function (value) {
        return _vm.board.createdAt = value;
      }
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "name",
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [['about'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1) : _vm._e(), ['about'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "기사",
      "persistent-placeholder": "",
      "hide-details": "",
      "auto-grow": "",
      "outlined": "",
      "rows": "1",
      "row-height": "15"
    },
    model: {
      value: _vm.board.about.content,
      callback: function ($$v) {
        _vm.$set(_vm.board.about, "content", $$v);
      },
      expression: "board.about.content"
    }
  })], 1) : _vm._e(), !['partners', 'about'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1) : _vm._e()], 1), ['partners'].includes(_vm.board.code) ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "회사소개",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.company.about,
      callback: function ($$v) {
        _vm.$set(_vm.board.company, "about", $$v);
      },
      expression: "board.company.about"
    }
  })], 1)], 1) : _vm._e(), ['partners'].includes(_vm.board.code) ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "회사 URL",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.company.URL,
      callback: function ($$v) {
        _vm.$set(_vm.board.company, "URL", $$v);
      },
      expression: "board.company.URL"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), !['text'].includes(_vm.$route.query.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.board.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.board.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "썸네일 이미지",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), ['text', 'thumbnail'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("첨부파일")]), _c('v-card-text', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "파일 선택",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.board.files[0],
      callback: function ($$v) {
        _vm.$set(_vm.board.files, 0, $$v);
      },
      expression: "board.files[0]"
    }
  }), _c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "파일 선택",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.board.files[1],
      callback: function ($$v) {
        _vm.$set(_vm.board.files, 1, $$v);
      },
      expression: "board.files[1]"
    }
  })], 1)], 1)], 1) : _vm._e(), ['forms'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("파일")]), _c('v-card-text', [_c('v-file-input', {
    attrs: {
      "show-size": ""
    },
    model: {
      value: _vm.board.files[0],
      callback: function ($$v) {
        _vm.$set(_vm.board.files, 0, $$v);
      },
      expression: "board.files[0]"
    }
  })], 1)], 1) : _vm._e(), ['text', 'thumbnail'].includes(_vm.$route.query.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }