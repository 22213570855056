var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.headline))]), !_vm.isCode(['consignment-sale', 'private-sale']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_vm.$route.params._form ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.name,
      "label": "작성자",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.form.createdAt ? _vm.form.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.code == 'inquiry' ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1) : _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', [_vm._v("정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_vm._v("생년월일 (혹은 사업자번호)")]), _c('v-col', [_vm._v(_vm._s(_vm.form.birthDate))])], 1), _c('v-row', [_c('v-col', [_vm._v("주소")]), _c('v-col', [_vm._v(_vm._s(_vm.form.address))])], 1), _c('v-row', [_c('v-col', [_vm._v("기부금 영수증 수령")]), _c('v-col', [_vm._v(_vm._s(_vm.form.inquiry))])], 1), _c('v-row', [_c('v-col', [_vm._v("주민등록번호 앞자리 (혹은 사업자등록번호)")]), _c('v-col', [_vm._v(_vm._s(_vm.form.personalNum))])], 1), _c('v-row', [_c('v-col', [_vm._v("후원유형")]), _c('v-col', [_vm._v(_vm._s(_vm.form.donationiType))])], 1), _c('v-row', [_c('v-col', [_vm._v("후원메모")]), _c('v-col', [_vm._v(_vm._s(_vm.form.memo))])], 1)], 1), _c('v-card', [_c('v-card-title', [_vm._v("CMS 자동이체 후원계좌정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-row', [_c('v-col', [_vm._v("은행명")]), _c('v-col', [_vm._v(_vm._s(_vm.form.bank))])], 1), _c('v-row', [_c('v-col', [_vm._v("계좌번호")]), _c('v-col', [_vm._v(_vm._s(_vm.form.accountNum))])], 1), _c('v-row', [_c('v-col', [_vm._v("상세주소")]), _c('v-col', [_vm._v(_vm._s(_vm.form.detailAddress))])], 1), _c('v-row', [_c('v-col', [_vm._v("이체일")]), _c('v-col', [_vm._v(_vm._s(_vm.form.transferDate))])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-divider'), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.form.reply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reply", $$v);
      },
      expression: "form.reply"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        _vm.form.reply = null;
      }
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }