<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" class="overflow-visible">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">연어다음 상품설정</v-col>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-date-field v-model="campaign.product.saleDate" label="시세 적용 날짜" persistent-placeholder dense hide-details />
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
            </v-row>
            <v-layout class="mt-4 align-end">
                <span class="subtitle-2"><b>연어다음 캠페인 설정</b></span>
                <v-btn color="primary" @click="pushCampaginItem()" >항목추가</v-btn>
            </v-layout>
            <template>
                <v-row v-for="campaign in campaigns" :key="campaign._id">
                    <v-col cols="12" md="3">
                        <v-card>
                            <v-card-title>
                                <span class="caption"><b>분류코드</b></span>
                                    <v-text-field v-model="campaign.kind" readonly disabled outlined dense hide-details class="ml-4" />
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-card>
                            <v-card-title>
                                <span class="caption"><b>품목</b></span>
                                <!-- append-icon="mdi-content-save"  -->
                                <v-text-field v-model="campaign.product.name" outlined dense hide-details class="ml-4" />
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-card>
                            <v-card-title>
                                <span class="caption"><b>가격</b></span>
                                <v-text-field v-model="campaign.product.salePrice" outlined dense hide-details class="ml-4" />
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-btn @click="remove(campaign)">삭제</v-btn>
                    </v-col>
                </v-row>
            </template>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="update(campaigns)">저장</v-btn>

            <v-divider class="my-4" />
            
            <div>
                <div class="caption primary--text"><b>※ 주의사항</b></div>
                <div class="caption"><b>* 캠페인 설정값은 예전에 신청한 내역에는 영향을 주지 않습니다.</b></div>
                <div class="caption"><b>* 캠페인 설정값을 변경 후에는 저장버튼을 누르셔야 시스템에 반영이 됩니다.</b></div>
            </div>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import xlsx from "xlsx";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import { campaigns } from '@/api/console/uthestar/campaigns';


export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                // searchKey: this.$route.query.searchKey || null,
                // searchValue: this.$route.query.searchValue,
                createdAt: (Array.isArray(this.$route.query?.createdAt) ? [this.$route.query?.createdAt?.[0] || this.$dayjs().format("YYYY-MM-DD"), this.$route.query?.createdAt?.[1]] : [this.$route.query?.createdAt || this.$dayjs().format("YYYY-MM-DD"), undefined]).filter(date => !!date),
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },

            kind: 40,

            campaigns:[],

            campaign: {
                product: {}
            },

            searchKeys: [
                // { text: "작성자", value: "writer.name" },
                { text: "받는분 이름", value: "receiver.name" },
                { text: "받는분 연락처", value: "receiver.phone" },
                { text: "받는분 이메일", value: "receiver.email" },
            ],

            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            let {campaigns}  = await api.console.uthestar.campaigns.gets({ params: { code: "salmon-campaign" } });
            let campaign = campaigns[0];
            this.campaign.product.saleDate = campaign.product.saleDate;
            this.campaigns= campaigns;

            this.search(false);
        },
        pushCampaginItem(){
            this.campaigns.push({
                product: {},
                kind: this.kind,
                tempId: Math.random()
            })  
        },
        async search(routable = true) {
            if(routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            var { summary, salmons } = await api.console.uthestar.salmons.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.salmons = salmons;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        
        async remove(campaign) {
            // DB 저장 전 삭제
            if(!campaign._id){
                const index = this.campaigns.findIndex((item)=> item.tempId == campaign.tempId);
                if(-1 <index) this.campaigns.splice(index, 1);
            }
            // DB 저장 후 삭제
            else{
                if (confirm("항목을 삭제하시겠습니까?")) {
                    await api.console.uthestar.campaigns.delete(campaign);
                    await this.search(false);
                    const index = this.campaigns.findIndex((item)=> item._id == campaign._id);
                    if(-1 <index) this.campaigns.splice(index, 1);
                    alert("삭제되었습니다");
                }
            }
            
        },

        async update(campaigns){
            for(let campaign of campaigns){
                campaign.product.saleDate = this.campaign.product.saleDate
                await api.console.uthestar.campaigns[campaign._id ? `put` : `post`]({
                    ...campaign,
                }).then(()=>this.init());
            } 
            
            alert("저장되었습니다");
        },

        async excel(type = ""){

            var { salmons: items } = await api.console.uthestar.salmons.gets({
                headers: {
                    skip: 0,
                    limit: 0,
                },
                params: this.filter,
            });

            switch(type){
                case "order": {
                    var workbook = new xlsx.utils.book_new();
                    var worksheet = xlsx.utils.json_to_sheet(items.map(item => ({
                        [`예약구분`]: "",
                        [`집하예정일`]: "",
                        [`받는분성명`]: item.receiver.name,
                        [`받는분전화번호`]: item.receiver.phone,
                        [`받는분기타연락처`]: "",
                        [`우편번호`]: item.receiver.postcode,
                        [`받는분주소(전체, 분할)`]: (item.receiver.address1 || '') + " " + (item.receiver.address2 || ''),
                        [`운송장번호`]: "",
                        [`고객주문번호`]: item.orderNo,
                        [`품목`]: item.product.name,
                        [`박스수량`]: "",
                        [`박스타입`]: "",
                        [`기본운임`]: "",
                        [`배송메세지`]: item.shippingMessage,
                        [`배송메세지2`]: "",
                        [`빈칸`]: "",
                        [`빈칸`]: "",
                        [`주문시간`]: this.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        [`상품명확인`]: "",
                        [`수량`]: item.quantity,
                        [`샵링커주문번호`]: "",
                        [`쇼핑몰명`]: "uthestar",
                    })));
                    
                    xlsx.utils.book_append_sheet(workbook, worksheet, "new");
                    xlsx.writeFile(workbook, "발주서.xlsx");

                    break;
                }
                default: {
                    var workbook = new xlsx.utils.book_new();
                    var worksheet = xlsx.utils.json_to_sheet(items.map(item => ({
                        [`신청일시`]: this.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        [`주문번호`]: item.orderNo,
                        [`받는분_성명`]: item.receiver.name,
                        [`받는분_연락처`]: item.receiver.phone,
                        [`받는분_이메일`]: item.receiver.email,
                        [`받는분_우편번호`]: item.receiver.postcode,
                        [`받는분_기본주소`]: item.receiver.address1,
                        [`받는분_상세주소`]: item.receiver.address2,
                        [`품목`]: item.product.name,
                        [`가격`]: item.product.salePrice,
                        [`수량`]: item.quantity,
                        [`가격합산`]: (+item.paymentAmount).format(),
                        [`배송메세지`]: item.shippingMessage,
                        [`기부금 영수증 발행`]: item.receiptIssuance.enabled ? "예" : "아니오",
                        [`발행정보_성명`]: item.receiptIssuance.name,
                        [`발행정보_타입`]: item.receiptIssuance.type,
                        [`발행정보_주민등록번호`]: item.receiptIssuance.type == `개인` ? item.receiptIssuance.number: '',
                        [`발행정보_사업자번호`]: item.receiptIssuance.type == `사업자` ? item.receiptIssuance.number: '',
                        [`발행정보_우편번호`]: item.receiptIssuance.postcode,
                        [`발행정보_기본주소`]: item.receiptIssuance.address1,
                        [`발행정보_상세주소`]: item.receiptIssuance.address2,
                        [`발행정보_연락처`]: item.receiptIssuance.phone,
                        [`수령방법`]: [{ type: "address", name: "우편수령" }, { type: "email", name: "이메일수령" }].find(method => method.type == item.receiptNotification.type)?.name,
                        [`수령정보`]: item.receiptNotification.type == "address" ? `[${item.receiptNotification.postcode || ''}] ${item.receiptNotification.address1 || ''} ${item.receiptNotification.address2 || ''}` : item.receiptNotification.email
                    })));
                    
                    xlsx.utils.book_append_sheet(workbook, worksheet, "new");
                    xlsx.writeFile(workbook, "연어다음_신청내역.xlsx");

                    break;
                }
            }
        },
    },
};
</script>
