<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" class="overflow-visible">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">연어다음 신청내역</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[0]" label="신청일자(시작)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[1]" label="신청일자(종료)" persistent-placeholder dense hide-details />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" placeholder=":: 전체 ::" clearable persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" clearable persistent-placeholder :disabled="!filter.searchKey" dense hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" rounded text @click="page = 1, search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-layout class="mt-4 align-end">
                <span class="subtitle-2"><b>검색수: {{ summary.totalCount }}</b></span>
                <v-btn color="white blue--text" elevation="1" class="ml-auto" @click="() => excel(`order`)"><v-icon left>mdi-microsoft-excel</v-icon>발주서 다운로드</v-btn>
                <v-btn color="white green--text" elevation="1" class="ml-2" @click="excel()"><v-icon left>mdi-microsoft-excel</v-icon>엑셀 다운로드</v-btn>
            </v-layout>

            <v-data-table :items="salmons" :headers="salmonsHeaders" hide-default-footer disable-sort disable-pagination class="mt-2 elevation-1">
                <template #[`item.createdAt`]="{item}">
                    {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
                <template #[`item.receiver`]="{item}">
                    <div>{{ item.receiver.name }}</div>
                    <div>{{ item.receiver.phone }}</div>
                    <div>{{ item.receiver.email }}</div>
                </template>
                <template #[`item.receiver.address`]="{item}">
                    [{{ item.receiver.postcode }}] {{ item.receiver.address1 }} {{ item.receiver.address2 }}
                </template>
                <template #[`item.receiptIssuance.registrationNumber`]="{item}">
                    <span v-if="item.receiptIssuance.type == `개인`">{{ item.receiptIssuance.number }}</span>
                </template>
                <template #[`item.receiptIssuance.businessNumber`]="{item}">
                    <span v-if="item.receiptIssuance.type == `사업자`">{{ item.receiptIssuance.number }}</span>
                </template>
                <template #[`item.product.salePrice`]="{item}">
                    {{ (+item.product.salePrice).format() }}
                </template>
                <template #[`item.paymentAmount`]="{item}">
                    {{ (+item.paymentAmount).format() }}
                </template>
                <template #[`item.receiptIssuance.enabled`]="{item}">
                    {{ item.receiptIssuance.enabled ? "예" : "아니오" }}
                </template>
                <template #[`item.receiptIssuance.address`]="{item}">
                    [{{ item.receiptIssuance.postcode }}] {{ item.receiptIssuance.address1 }} {{ item.receiptIssuance.address2 }}
                </template>
                <template #[`item.receiptNotification.type`]="{item}">
                    <span v-if="item.receiptNotification.type == `address`">우편 수령</span>
                    <span v-if="item.receiptNotification.type == `email`">이메일 수령</span>
                    <span v-if="item.receiptNotification.type == `kakaoId`">카카오톡 수령</span>
                </template>
                <template #[`item.receiptNotification`]="{item}">
                    <span v-if="item.receiptNotification.type == `address`">[{{ item.receiptNotification.postcode }}] {{ item.receiptNotification.address1 }} {{ item.receiptNotification.address2 }}</span>
                    <span v-if="item.receiptNotification.type == `email`">{{ item.receiptNotification.email }}</span>
                    <span v-if="item.receiptNotification.type == `kakaoId` && !item.receiptNotification.phone">{{ item.receiptNotification.id }}</span>
                    <span v-if="item.receiptNotification.type == `kakaoId` && item.receiptNotification.phone">{{ item.receiptNotification.phone }}</span>
                </template>
            </v-data-table>

            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="my-4" @input="search()"></v-pagination>
                    </v-responsive>
                </v-layout>
</template>

<script>
import api from "@/api";
import xlsx from "xlsx";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";


export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue,
                createdAt: (Array.isArray(this.$route.query?.createdAt) ? [this.$route.query?.createdAt?.[0] || this.$dayjs().format("YYYY-MM-DD"), this.$route.query?.createdAt?.[1]] : [this.$route.query?.createdAt || this.$dayjs().format("YYYY-MM-DD"), undefined]).filter(date => !!date),
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },

            salmons: [],
            salmonsHeaders: [
                { align: "center", width: 180, text: "신청일시", value: "createdAt" },
                { align: "center", width: 150, text: "주문번호", value: "orderNo" },
                { align: "center", width: 80, text: "분류코드", value: "kind" },
                { align: "center", width: 100, text: "받는분_이름", value: "receiver.name" },
                { align: "center", width: 100, text: "받는분_연락처", value: "receiver.phone" },
                { align: "center", width: 200, text: "받는분_이메일", value: "receiver.email" },
                { align: "center", width: 300, text: "주소", value: "receiver.address" },
                { align: "center", width: 250, text: "품목", value: "product.name"},
                { align: "center", width: 100, text: "가격", value: "product.salePrice"},
                { align: "center", width: 100, text: "수량", value: "quantity"},
                { align: "center", width: 100, text: "가격 합산", value: "paymentAmount"},
                { align: "center", width: 200, text: "배송메세지", value: "shippingMessage"},
                { align: "center", width: 120, text: "기부금 영수증 발행", value: "receiptIssuance.enabled"},
                { align: "center", width: 120, text: "발행정보_성명", value: "receiptIssuance.name"},
                { align: "center", width: 200, text: "발행정보_타입", value: "receiptIssuance.type"},
                { align: "center", width: 200, text: "발행정보_주민등록번호", value: "receiptIssuance.registrationNumber"},
                { align: "center", width: 200, text: "발행정보_사업자번호", value: "receiptIssuance.businessNumber"},
                { align: "center", width: 150, text: "발행정보_연락처", value: "receiptIssuance.phone"},
                { align: "center", width: 300, text: "발행정보_주소", value: "receiptIssuance.address"},
                { align: "center", width: 100, text: "수령방법", value: "receiptNotification.type"},
                { align: "center", width: 300, text: "수령정보", value: "receiptNotification"},

                { align: "center", width: 100, text: "", value: "actions" },
            ],

            campaign: {
                product: {}
            },

            searchKeys: [
                // { text: "작성자", value: "writer.name" },
                { text: "받는분 이름", value: "receiver.name" },
                { text: "받는분 연락처", value: "receiver.phone" },
                { text: "받는분 이메일", value: "receiver.email" },
            ],

            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init(){
            let { campaigns } = await api.console.uthestar.campaigns.gets({ params: { code: "salmon-campaign" } });
            let[campaign] = campaigns.map(item => ({...item, tempId: Math.random()}));
            this.campaign = campaign

            this.search(false);
        },
        async search(routable = true) {
            if(routable) {
                this.$router.push({
                    query: { ...this.filter, page: this.page },
                });
            }

            var { summary, salmons } = await api.console.uthestar.salmons.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.salmons = salmons;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        
        async remove(form) {
            if (confirm("폼메일을 삭제하시겠습니까?")) {
                await api.console.forms.delete(form);
                await this.search(false);

                alert("삭제되었습니다");
            }
        },

        async update(campaign){
            await api.console.uthestar.campaigns[campaign._id ? `put` : `post`]({
                ...campaign,
            });

            alert("저장되었습니다");
        },

        async excel(type = ""){

            var { salmons: items } = await api.console.uthestar.salmons.gets({
                headers: {
                    skip: 0,
                    limit: 0,
                },
                params: this.filter,
            });

            switch(type){
                case "order": {
                    var workbook = new xlsx.utils.book_new();
                    var worksheet = xlsx.utils.json_to_sheet(items.map(item => ({
                        [`예약구분`]: "",
                        [`집하예정일`]: "",
                        [`받는분성명`]: item.receiver.name,
                        [`받는분전화번호`]: item.receiver.phone,
                        [`받는분기타연락처`]: "",
                        [`우편번호`]: item.receiver.postcode,
                        [`받는분주소(전체, 분할)`]: (item.receiver.address1 || '') + " " + (item.receiver.address2 || ''),
                        [`운송장번호`]: "",
                        [`고객주문번호`]: item.orderNo,
                        // [`품목`]: item.product.name,
                        [`품목`]: `${item.product.name} [${item.quantity}]`,
                        [`박스수량`]: "",
                        [`박스타입`]: "",
                        [`기본운임`]: "",
                        [`배송메세지`]: item.shippingMessage,
                        [`배송메세지2`]: "",
                        [`빈칸`]: "",
                        [`빈칸2`]: "",
                        [`주문시간`]: this.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        [`상품명확인`]: "",
                        [`수량`]: item.quantity,
                        [`샵링커주문번호`]: "",
                        [`쇼핑몰명`]: "uthestar",
                    })));
                    
                    xlsx.utils.book_append_sheet(workbook, worksheet, "new");
                    xlsx.writeFile(workbook, "발주서.xls");

                    break;
                }
                default: {
                    var workbook = new xlsx.utils.book_new();
                    var worksheet = xlsx.utils.json_to_sheet(items.map(item => ({
                        [`신청일시`]: this.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        [`주문번호`]: item.orderNo,
                        [`받는분_성명`]: item.receiver.name,
                        [`받는분_연락처`]: item.receiver.phone,
                        [`받는분_이메일`]: item.receiver.email,
                        [`받는분_우편번호`]: item.receiver.postcode,
                        [`받는분_기본주소`]: item.receiver.address1,
                        [`받는분_상세주소`]: item.receiver.address2,
                        [`품목`]: item.product.name,
                        [`가격`]: item.product.salePrice,
                        [`수량`]: item.quantity,
                        [`가격합산`]: (+item.paymentAmount).format(),
                        [`배송메세지`]: item.shippingMessage,
                        [`기부금 영수증 발행`]: item.receiptIssuance.enabled ? "예" : "아니오",
                        [`발행정보_성명`]: item.receiptIssuance.name,
                        [`발행정보_타입`]: item.receiptIssuance.type,
                        [`발행정보_주민등록번호`]: item.receiptIssuance.type == `개인` ? item.receiptIssuance.number: '',
                        [`발행정보_사업자번호`]: item.receiptIssuance.type == `사업자` ? item.receiptIssuance.number: '',
                        [`발행정보_우편번호`]: item.receiptIssuance.postcode,
                        [`발행정보_기본주소`]: item.receiptIssuance.address1,
                        [`발행정보_상세주소`]: item.receiptIssuance.address2,
                        [`발행정보_연락처`]: item.receiptIssuance.phone,
                        [`수령방법`]: [{ type: "address", name: "우편수령" }, { type: "email", name: "이메일수령" }, { type: "kakaoId", name: "카카오톡 수령" }].find(method => method.type == item.receiptNotification.type)?.name,
                        [`수령정보`]: [
                                    { type: "address", info: `[${item.receiptNotification.postcode || ''}] ${item.receiptNotification.address1 || ''} ${item.receiptNotification.address2 || ''}` },
                                    { type: "email", info: item.receiptNotification.email }, 
                                    { type: "kakaoId", info: item.receiptNotification.phone ? `${item.receiptNotification.phone}` : `${item.receiptNotification.id}`}]
                                    .find(method=>
                                        method.type == item.receiptNotification.type)?.info
                    })));
                    xlsx.utils.book_append_sheet(workbook, worksheet, "new");
                    xlsx.writeFile(workbook, "연어다음_신청내역.xls");

                    break;
                }
            }
        },
    },
};
</script>
